import { handleActions } from "redux-actions";
import axios from "axios";
import { asyncActions } from "../util/util";
import { DOMAIN } from "../../constants";
import Auth from "../util/auth";

const initialState = {
  changePassword: {},
  login: {},
  logout: {},
  register: {},
  getRole: {},
  getAllUsers: {},
  getUser: {},
  updateUser: {},
  deleteUser: {}
};

export const changePassword = asyncActions(
  "CHANGE_PASSWORD",
  ({ newPassword, oldPassword }) => {
    return axios.post(
      `${DOMAIN}/auth/change_password`,
      { new_password: newPassword, old_password: oldPassword },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    );
  }
);

export const logout = asyncActions("LOGOUT", () => {
  return axios.delete(`${DOMAIN}/auth/logout`, {
    headers: {
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  });
});

export const login = asyncActions("LOGIN", ({ email, password }) => {
  return axios.post(
    `${DOMAIN}/auth/login`,
    { email, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
});

export const register = asyncActions(
  "REGISTER",
  ({ email, password, name }) => {
    return axios.post(
      `${DOMAIN}/user/create`,
      {
        email,
        password,
        name,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    );
  }
);

export const getRole = asyncActions("GET_ROLE", () => {
  return axios.get(`${DOMAIN}/user/roles`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  });
});

export const getAllUsers = asyncActions("GET_ALL_USERS", () => {
  return axios.get(`${DOMAIN}/user/all`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  });
});

export const getUser = asyncActions("GET_USER", () => {
  return axios.get(`${DOMAIN}/user/info`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  });
});

export const updateUser = asyncActions("UPDATE_USER", ({ email, password, name, id }) => {
  return axios.put(`${DOMAIN}/user?id=${id}`, {
    email,
    password,
    name,
  },{
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  });
});

export const deleteUser = asyncActions("DELETE_USER", (id) => {
  return axios.delete(`${DOMAIN}/user?id=${id}`, {
    headers: {
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  });
});

export const changePasswordHandleActions = handleActions(
  {
    [changePassword.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [changePassword.SUCCESS]: (state, { payload, meta }) => {
      return { status: "success", data: payload.response };
    },
    [changePassword.FAILURE]: (state, { payload, meta }) => {
      return {
        status: "fail",
        error: payload.error,
      };
    },
  },
  initialState.changePassword
);

export const loginHandleActions = handleActions(
  {
    [login.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [login.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: "success",
        data: payload.response,
      };
    },
    [login.FAILURE]: (state, { payload, meta }) => {
      return {
        status: "fail",
        error: payload.error,
      };
    },
  },
  initialState.login
);

export const logoutHandleActions = handleActions(
  {
    [logout.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [logout.SUCCESS]: (state, { payload, meta }) => {
      return { status: "success", data: payload.response };
    },
    [logout.FAILURE]: (state, { payload, meta }) => {
      return { status: "fail", error: payload.error };
    },
  },
  initialState.logout
);

export const registerHandleActions = handleActions(
  {
    [register.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [register.SUCCESS]: (state, { payload, meta }) => {
      return { status: "success", data: payload.response };
    },
    [register.FAILURE]: (state, { payload, meta }) => {
      return { status: "fail", error: payload.error };
    },
  },
  initialState.register
);

export const getRoleHandleActions = handleActions(
  {
    [getRole.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [getRole.SUCCESS]: (state, { payload, meta }) => {
      return { status: "success", data: payload.response };
    },
    [getRole.FAILURE]: (state, { payload, meta }) => {
      return { status: "fail", error: payload.error };
    },
  },
  initialState.getRole
);

export const getAllUsersHandleActions = handleActions(
  {
    [getAllUsers.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [getAllUsers.SUCCESS]: (state, { payload, meta }) => {
      return { status: "success", data: payload.response };
    },
    [getAllUsers.FAILURE]: (state, { payload, meta }) => {
      return { status: "fail", error: payload.error };
    },
  },
  initialState.getAllUsers
);

export const getUserHandleActions = handleActions(
  {
    [getUser.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [getUser.SUCCESS]: (state, { payload, meta }) => {
      return { status: "success", data: payload.response };
    },
    [getUser.FAILURE]: (state, { payload, meta }) => {
      return { status: "fail", error: payload.error };
    },
  },
  initialState.getUser
);

export const updateUserHandleActions = handleActions(
  {
    [updateUser.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [updateUser.SUCCESS]: (state, { payload, meta }) => {
      return { status: "success", data: payload.response };
    },
    [updateUser.FAILURE]: (state, { payload, meta }) => {
      return { status: "fail", error: payload.error };
    },
  },
  initialState.updateUser
);

export const deleteUserHandleActions = handleActions(
  {
    [deleteUser.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [deleteUser.SUCCESS]: (state, { payload, meta }) => {
      return { status: "success", data: payload.response };
    },
    [deleteUser.FAILURE]: (state, { payload, meta }) => {
      return { status: "fail", error: payload.error };
    },
  },
  initialState.deleteUser
);

