const isLoggedIn = () => {
  return localStorage.getItem("token") || sessionStorage.getItem("token");
};

const setUser = (user, isRemembered) => {
  const { token } = user;
  isRemembered
    ? localStorage.setItem("token", token)
    : sessionStorage.setItem("token", token);
};

const setRole = (role) => {
  localStorage.setItem("role", role);
};

const getRole = () => {
  return localStorage.getItem("role");
};

const getToken = () => {
  // if (localStorage.getItem("token")) {
  return localStorage.getItem("token") || sessionStorage.getItem("token");
  // }
  // return sessionStorage.getItem("token");
};

const getUserId = () => {
  if (localStorage.getItem("user_id")) {
    return localStorage.getItem("user_id");
  }
  return sessionStorage.getItem("user_id");
};

const getRoleName = () => {
  if (localStorage.getItem("role_name")) {
    return localStorage.getItem("role_name");
  }
  return sessionStorage.getItem("role_name");
};

const logout = () => {
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");
};

const login = (user, isRemembered) => {
  setUser(user, isRemembered);
};

export default {
  login,
  logout,
  isLoggedIn,
  getToken,
  getUserId,
  getRoleName,
  setRole,
  getRole,
};
