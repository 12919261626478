import { handleActions } from 'redux-actions';
// import axios from "axios";
import { loggedInActions, switchLanguageActions } from '../util/util';
// import { DOMAIN } from "../../constants";
// import Auth from "../util/auth";
// import { createAction } from "@reduxjs/toolkit";
// import { useSelector } from "react-redux";

const initialState = {
  language: 'EN',
  isLoggedIn: false,
};

export const switchLanguage = switchLanguageActions(
  (state) => state.switchLanguage
);

export const switchLanguageHandleActions = handleActions(
  {
    [switchLanguage.SUCCESS]: (state, { payload, meta }) => {
      return payload.response;
    },
  },
  initialState.language
);

export const loggedIn = loggedInActions((state) => state.loggedIn);

export const loggedInHandleActions = handleActions(
  {
    [loggedIn.SUCCESS]: (state, { payload, meta }) => {
      return payload.response;
    },
  },
  initialState.isLoggedIn
);
