import React from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import 'react-toastify/dist/ReactToastify.css';
import GlobalRouter from './router';
import './css/style.css';
import './css/responsive.css';
import './css/jquery.mCustomScrollbar.min.css';
import { PersistGate } from 'redux-persist/es/integration/react';

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalRouter />
      </PersistGate>
    </Provider>
  );
}
