import { handleActions } from 'redux-actions';
import axios from 'axios';
import { addActions, asyncActions, asyncApi } from '../util/util';
import { DOMAIN } from '../../constants';
import Auth from '../util/auth';

const initialState = {
  getAllProducts: [],
  getAllProductTypes: [],
  getAllProductsByType: [],
  getProductType: {},
  createProductType: {},
  updateProductType: {},
  deleteProductType: {},
  createProduct: {},
  updateProduct: {},
  deleteProduct: {},
  updateImageProduct: {},
};

export const getAllProducts = asyncActions('GET_ALL_PRODUCTS', () => {
  return axios.get(`${DOMAIN}/product`);
});

export const getAllProductsHandleActions = handleActions(
  {
    [getAllProducts.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [getAllProducts.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [getAllProducts.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.getAllProducts
);

export const getAllProductTypes = asyncActions('GET_ALL_PRODUCT_TYPES', () => {
  return axios.get(`${DOMAIN}/product/type`);
});

export const getAllProductTypesHandleActions = handleActions(
  {
    [getAllProductTypes.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [getAllProductTypes.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [getAllProductTypes.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.getAllProductTypes
);

export const getAllProductsByType = asyncActions(
  'GET_ALL_PRODUCTS_BY_TYPE',
  (productType) => {
    return axios.get(`${DOMAIN}/product/type/${productType}/all`);
  }
);

export const getAllProductsByTypeHandleActions = handleActions(
  {
    [getAllProductsByType.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [getAllProductsByType.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [getAllProductsByType.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.getAllProductsByType
);

export const createProductType = addActions(
  'CREATE_PRODUCT_TYPE',
  ({ nameEn, nameVn }) => {
    return axios.post(
      `${DOMAIN}/product/type`,
      {
        name_eng: nameEn,
        name_viet: nameVn,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    );
  }
);

export const createProductTypeHandleActions = handleActions(
  {
    [createProductType.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [createProductType.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [createProductType.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.createProductType
);

export const getProductType = asyncActions(
  'GET_PRODUCT_TYPE',
  (productType) => {
    return axios.get(`${DOMAIN}/product/type/${productType}`);
  }
);

export const getProductTypeHandleActions = handleActions(
  {
    [getProductType.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [getProductType.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [getProductType.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.getProductType
);

export const updateProductType = asyncActions(
  'UPDATE_PRODUCT_TYPE',
  ({ productType, nameEn, nameVn }) => {
    return axios.put(
      `${DOMAIN}/product/type/${productType}`,
      { name_eng: nameEn, name_viet: nameVn },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    );
  }
);

export const updateProductTypeHandleActions = handleActions(
  {
    [updateProductType.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [updateProductType.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [updateProductType.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.updateProductType
);

export const deleteProductType = asyncActions(
  'DELETE_PRODUCT_TYPE',
  (productType) => {
    return axios.delete(`${DOMAIN}/product/type/${productType}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    });
  }
);

export const deleteProductTypeHandleActions = handleActions(
  {
    [deleteProductType.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [deleteProductType.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [deleteProductType.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.deleteProductType
);

export const createProduct = asyncActions(
  'CREATE_PRODUCT',
  ({ nameVn, nameEn, productType }) => {
    return axios.post(
      `${DOMAIN}/product/create?type_id=${productType}`,
      {
        name_viet: nameVn,
        name_eng: nameEn,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    );
  }
);

export const createProductHandleActions = handleActions(
  {
    [createProduct.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [createProduct.SUCCESS]: (state, { payload, meta }) => {
      return { status: 'success', data: payload.response };
    },
    [createProduct.FAILURE]: (state, { payload, meta }) => {
      return { status: 'fail', error: payload.error };
    },
  },
  initialState.createProduct
);

export const updateProduct = asyncActions(
  'UPDATE_PRODUCT',
  ({ nameVn, nameEn, id }) => {
    return axios.put(
      `${DOMAIN}/product/id?product_id=${id}`,
      {
        name_viet: nameVn,
        name_eng: nameEn,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    );
  }
);

export const updateProductHandleActions = handleActions(
  {
    [updateProduct.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [updateProduct.SUCCESS]: (state, { payload, meta }) => {
      return { status: 'success', data: payload.response };
    },
    [updateProduct.FAILURE]: (state, { payload, meta }) => {
      return { status: 'fail', error: payload.error };
    },
  },
  initialState.updateProduct
);

export const deleteProduct = asyncActions('DELETE_PRODUCT', (id) => {
  return axios.delete(`${DOMAIN}/product/id?product_id=${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  });
});

export const deleteProductHandleActions = handleActions(
  {
    [deleteProduct.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [deleteProduct.SUCCESS]: (state, { payload, meta }) => {
      return { status: 'success', data: payload.response };
    },
    [deleteProduct.FAILURE]: (state, { payload, meta }) => {
      return { status: 'fail', error: payload.error };
    },
  },
  initialState.deleteProduct
);

export const updateImageProduct = asyncActions(
  'UPDATE_IMAGE_PRODUCT',
  ({ productType, file }) => {
    const form = new FormData();
    form.append('file', file);
    return axios.put(`${DOMAIN}/product/type/image?id=${productType}`, form, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    });
  }
);

export const updateImageProductHandleActions = handleActions(
  {
    [updateImageProduct.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [updateImageProduct.SUCCESS]: (state, { payload, meta }) => {
      return { status: 'success', data: payload.response };
    },
    [updateImageProduct.FAILURE]: (state, { payload, meta }) => {
      return { status: 'fail', error: payload.error };
    },
  },
  initialState.updateImageProduct
);
