import React from 'react';
import { useSelector } from 'react-redux';

const CustomOfferItem = ({ item }) => {
  const { name_eng: nameEn, name_viet: nameVn, image } = item;
  const language = useSelector((state) => state.switchLanguage);
  return (
    ((language === 'EN' && nameEn) || (language === 'VN' && nameVn)) && (
      <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
        <div className='offer_box'>
          <figure>
            <img src={`data:image/png;base64,${image}`} alt='img' />
          </figure>
          <h3>{language === 'EN' ? nameEn : nameVn}</h3>
        </div>
      </div>
    )
  );
};

export default CustomOfferItem;
