import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllProducts } from '../../redux/reducer/product';
import ProductType from '../../components/ProductPage/ProductType';
import { useParams } from 'react-router';
import { useDidUpdateEffect } from '../../redux/util/util';

const ProductPage = () => {
  const dispatch = useDispatch();
  const { productType: id } = useParams();
  const { data: types, status: getAllStatus } = useSelector(
    (state) => state.getAllProducts
  );
  const { status: createStatus, error: createError } = useSelector(
    (state) => state.createProduct
  );
  const { status: deleteStatus, error: deleteError } = useSelector(
    (state) => state.deleteProduct
  );
  const { status: updateStatus, error: updateError } = useSelector(
    (state) => state.updateProduct
  );

  useDidUpdateEffect(() => {
    if (updateStatus === 'success') {
      dispatch(getAllProducts());
      toast.success('Successfully updated');
    } else if (updateStatus === 'fail') toast.error(updateError);
  }, [updateStatus]);

  useDidUpdateEffect(() => {
    if (deleteStatus === 'success') {
      dispatch(getAllProducts());
      toast.success('Successfully deleted');
    } else if (deleteStatus === 'fail') toast.error(deleteError);
  }, [deleteStatus]);

  useDidUpdateEffect(() => {
    if (createStatus === 'success') {
      dispatch(getAllProducts());
      toast.success('Successfully created');
    } else if (createStatus === 'fail') toast.error(createError);
  }, [createStatus]);

  useDidUpdateEffect(() => {
    if (getAllStatus === 'success')
      setTimeout(
        () => document.getElementById(id)?.scrollIntoView({ block: 'center' }),
        500
      );
  }, [getAllStatus]);

  useEffect(() => {
    types &&
      id &&
      document.getElementById(id).scrollIntoView({ block: 'center' });
  }, [types, id]);

  useEffect(() => {
    dispatch(getAllProducts());
  }, []);

  return (
    <div style={{ width: '80%', margin: 'auto' }}>
      {types?.data &&
        Object.keys(types?.data).map((type) => (
          <ProductType key={type} productType={type} />
        ))}
    </div>
  );
};

export default ProductPage;
