import React, { useEffect } from "react";
import CustomSlider from "../../components/MainPage/CustomSlider";
import CustomAbout from "../../components/MainPage/CustomAbout";
import CustomOffer from "../../components/MainPage/CustomOffer";
import CustomProduct from "../../components/MainPage/CustomProduct";
import { useParams } from "react-router";

const MainPage = () => {
  const { section } = useParams();

  useEffect(() => {
    if (["about", "service", "contact"].includes(section)) {
      setTimeout(
        () =>
          document
            .getElementById(section)
            ?.scrollIntoView({ behavior: "smooth" }),
        500
      );
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [section]);

  return (
    <div>
      <CustomSlider />
      <CustomAbout />
      <CustomProduct />
      <CustomOffer />
    </div>
  );
};

export default MainPage;
