import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LANGUAGE_SWITCHER from "../../languageSwitcher/languageDict";
import { login, register } from "../../redux/reducer/auth";
import { Form, Modal, Button } from "react-bootstrap";

const LogInRegisterModal = ({ show, setShow, isLogIn }) => {
  const language = useSelector((state) => state.switchLanguage);
  const { status: logInStatus } = useSelector((state) => state.login);
  const { status: registerStatus } = useSelector((state) => state.register);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const dispatch = useDispatch();

  const submitModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (email !== "" && password !== "") {
      isLogIn
        ? dispatch(login({ email, password }))
        : passwordConfirm !== "" && dispatch(register({ email, password }));
    }
  };

  const close = () => {
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
    setShow(false);
  };

  useEffect(() => {
    if (logInStatus === "success") {
      setShow(false);
    }
  }, [logInStatus]);

  useEffect(() => {
    if (registerStatus === "success") {
      setShow(false);
    }
  }, [registerStatus]);

  return (
    <Modal show={show} onHide={() => close()} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {isLogIn
            ? LANGUAGE_SWITCHER[language].logIn
            : LANGUAGE_SWITCHER[language].register}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate onSubmit={submitModal}>
          <Form.Group controlId="formGroupEmail">
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ border: "1px solid #ced4da" }}
              isInvalid={email === ""}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ margin: "-20px 0 10px 5px" }}
            >
              {LANGUAGE_SWITCHER[language].emailError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGroupPassword">
            <Form.Control
              type="password"
              placeholder={LANGUAGE_SWITCHER[language].password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ border: "1px solid #ced4da" }}
              isInvalid={password === ""}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ margin: "-20px 0 10px 5px" }}
            >
              {LANGUAGE_SWITCHER[language].passwordError}
            </Form.Control.Feedback>
          </Form.Group>
          {!isLogIn && (
            <Form.Group controlId="formGroupPasswordConfirm">
              <Form.Control
                type="password"
                placeholder={LANGUAGE_SWITCHER[language].passwordConfirm}
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                style={{ border: "1px solid #ced4da" }}
                isInvalid={passwordConfirm === ""}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ margin: "-20px 0 10px 5px" }}
              >
                {LANGUAGE_SWITCHER[language].passwordConfirmError}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <div className="modal-button">
            <Button variant="secondary" onClick={() => close()}>
              {LANGUAGE_SWITCHER[language].back}
            </Button>
            <Button variant="primary" type="submit" id="confirm-button">
              {LANGUAGE_SWITCHER[language].confirm}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LogInRegisterModal;
