import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import { getAllServiceTypes } from '../../redux/reducer/service';
import { useDidUpdateEffect } from '../../redux/util/util';
import CustomAddItem from './CustomAddItem';
import CustomOfferItem from './CustomOfferItem';
import CustomOfferItemEdit from './CustomOfferItemEdit';

const CustomOffer = () => {
  const language = useSelector((state) => state.switchLanguage);
  const loggedIn = useSelector((state) => state.loggedIn);
  const dispatch = useDispatch();
  const { data: serviceTypes, status: getAllStatus } = useSelector(
    (state) => state.getAllServiceTypes
  );
  const { status: createStatus, error: createError } = useSelector(
    (state) => state.createServiceType
  );
  const { status: updateStatus, error: updateError } = useSelector(
    (state) => state.updateServiceType
  );
  const { status: deleteStatus, error: deleteError } = useSelector(
    (state) => state.deleteServiceType
  );
  const [types, setTypes] = useState();
  useDidUpdateEffect(() => {
    if (updateStatus === 'success') toast.success('Successfully updated');
    else if (updateStatus === 'fail') toast.error(updateError);
  }, [updateStatus]);

  useDidUpdateEffect(() => {
    if (deleteStatus === 'success') toast.success('Successfully deleted');
    else if (deleteStatus === 'fail') toast.error(deleteError);
  }, [deleteStatus]);

  useDidUpdateEffect(() => {
    if (createStatus === 'success') {
      toast.success('Successfully created');
    } else if (createStatus === 'fail') toast.error(createError);
  }, [createStatus]);

  useEffect(() => {
    if (getAllStatus === 'success') {
      setTypes(serviceTypes.data);
    }
  }, [getAllStatus]);

  useEffect(() => {
    setTimeout(() => dispatch(getAllServiceTypes()), 100);
  }, [updateStatus, createStatus, deleteStatus]);

  return (
    <div id='service' className='offer'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='title'>
              <h2>{LANGUAGE_SWITCHER[language].services}</h2>
            </div>
          </div>
        </div>
      </div>
      {loggedIn ? (
        <Row>
          {types &&
            Object.keys(types)?.map((id) => (
              <CustomOfferItemEdit item={types[id]} key={id} />
            ))}
          <CustomAddItem />
        </Row>
      ) : (
        <div className='offer-bg'>
          <div className='container'>
            <div className='row'>
              {types &&
                Object.keys(types)?.map((id) => (
                  <CustomOfferItem item={types[id]} key={id} />
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomOffer;
