const LANGUAGE_SWITCHER = {
  EN: {
    logIn: 'Log In',
    logOut: 'Log Out',
    register: 'Register',
    home: 'Home',
    about: 'About',
    products: 'Products',
    services: 'Services',
    contact: 'Contact Us',
    contactEn: 'Contact us in English',
    contactVn: 'Contact us in Vietnamese',
    address: 'Address',
    addressEn: 'Address in English',
    addressVn: 'Address in Vietnamese',
    search: 'Search',
    back: 'Back',
    confirm: 'Confirm',
    password: 'Password',
    passwordError: 'Please enter password',
    emailError: 'Please enter email',
    passwordConfirm: 'Confirm Password',
    passwordConfirmError: 'Password and Confirm Password do not match',
    serviceName: 'Service name',
    serviceNameVn: 'Service name in Vietnamese',
    serviceNameEn: 'Service name in English',
    serviceNameError: 'Please enter service name',
    productName: 'Product name',
    productNameVn: 'Product name in Vietnamese',
    productNameEn: 'Product name in English',
    productNameError: 'Please enter product name',
    seeMore: 'See More',
    error: 'Error',
    edit: 'Edit',
    delete: 'Delete',
    chooseFile: 'Choose image file',
    addUser: 'Add user',
    addService: 'Add service',
    addProduct: 'Add product',
    account: 'Account',
    changePassword: 'Change Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    newPasswordConfirm: 'New password confirm',
    add: 'ADD',
    update: 'Update',
    nameEn: "Company's English name",
    nameVn: "Company's Vietnamese name",
    introEn: 'Intro in English',
    introVn: 'Intro in Vietnamese',
    aboutEn: 'About Us in English',
    aboutVn: 'About Us in Vietnamese',
    quicklink: 'Quick Links',
  },
  VN: {
    logIn: 'Đăng nhập',
    logOut: 'Đăng xuất',
    register: 'Đăng ký',
    home: 'Trang chủ',
    about: 'Về chúng tôi',
    products: 'Sản phẩm',
    services: 'Dịch vụ',
    contact: 'Liên hệ',
    contactEn: 'Liên hệ tiếng Anh',
    contactVn: 'Liên hệ tiếng Việt',
    address: 'Địa chỉ',
    addressEn: 'Địa chỉ tiếng Anh',
    addressVn: 'Địa chỉ tiếng Việt',
    search: 'Tìm kiếm',
    back: 'Quay lại',
    confirm: 'Xác nhận',
    password: 'Mật khẩu',
    passwordError: 'Vui lòng nhập mật khẩu',
    emailError: 'Vui lòng nhập email',
    passwordConfirm: 'Xác nhận mật khẩu',
    passwordConfirmError: 'Mật khẩu không khớp',
    serviceName: 'Tên dịch vụ',
    serviceNameVn: 'Tên dịch vụ tiếng Việt',
    serviceNameEn: 'Tên dịch vụ tiếng Anh',
    productName: 'Tên sản phẩm',
    productNameVn: 'Tên sản phẩm tiếng Việt',
    productNameEn: 'Tên sản phẩm tiếng Anh',
    seeMore: 'Xem thêm',
    error: 'Lỗi',
    edit: 'Sửa',
    delete: 'Xóa',
    chooseFile: 'Chọn file ảnh',
    addUser: 'Thêm tài khoản',
    addService: 'Thêm dịch vụ',
    addProduct: 'Thêm sản phẩm',
    account: 'Tài khoản',
    changePassword: 'Đổi mật khẩu',
    oldPassword: 'Mật khẩu cũ',
    newPassword: 'Mật khẩu mới',
    newPasswordConfirm: 'Xác nhận mật khẩu mới',
    add: 'THÊM',
    update: 'Cập nhật',
    nameEn: 'Tên công ty tiếng Anh',
    nameVn: 'Tên công ty tiếng Việt',
    introEn: 'Giới thiệu tiếng Anh',
    introVn: 'Giới thiệu tiếng Việt',
    aboutEn: 'Về chúng tôi tiếng Anh',
    aboutVn: 'Về chúng tôi tiếng Việt',
    quicklink: 'Đường dẫn',
  },
};

export default LANGUAGE_SWITCHER;
