import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import { getAllProductTypes } from '../../redux/reducer/product';
import { useDidUpdateEffect } from '../../redux/util/util';
import CustomAddItem from './CustomAddItem';
import CustomProductItemEdit from './CustomProductItemEdit';

const CustomProduct = () => {
  const language = useSelector((state) => state.switchLanguage);
  const { data: productTypes, status: getAllStatus } = useSelector(
    (state) => state.getAllProductTypes
  );
  const { status: createStatus, error: createError } = useSelector(
    (state) => state.createProductType
  );
  const { status: updateStatus, error: updateError } = useSelector(
    (state) => state.updateProductType
  );
  const { status: deleteStatus, error: deleteError } = useSelector(
    (state) => state.deleteProductType
  );
  const loggedIn = useSelector((state) => state.loggedIn);
  const dispatch = useDispatch();
  const [types, setTypes] = useState();

  useDidUpdateEffect(() => {
    if (updateStatus === 'success') {
      dispatch(getAllProductTypes());
      toast.success('Successfully updated');
    } else if (updateStatus === 'fail') toast.error(updateError);
  }, [updateStatus]);

  useDidUpdateEffect(() => {
    if (deleteStatus === 'success') {
      dispatch(getAllProductTypes());
      toast.success('Successfully deleted');
    } else if (deleteStatus === 'fail') toast.error(deleteError);
  }, [deleteStatus]);

  useDidUpdateEffect(() => {
    if (createStatus === 'success') {
      dispatch(getAllProductTypes());
      toast.success('Successfully created');
    } else if (createStatus === 'fail') toast.error(createError);
  }, [createStatus]);

  useEffect(() => {
    if (getAllStatus === 'success') {
      setTypes(productTypes.data);
    }
  }, [getAllStatus]);

  return (
    <div id='product' className='product'>
      <div className='container'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='title'>
                <h2>
                  <strong className='black'>
                    {LANGUAGE_SWITCHER[language].products}
                  </strong>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Row>
        {types &&
          Object.keys(types).map((id) => (
            <CustomProductItemEdit item={types[id]} id={id} key={id} />
          ))}
        {loggedIn && <CustomAddItem isProduct />}
      </Row>
    </div>
  );
};

export default CustomProduct;
