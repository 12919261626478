import React, { useState } from 'react';
import { DropdownButton, Form, Dropdown, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import {
  deleteProductType,
  updateImageProduct,
  updateProductType,
} from '../../redux/reducer/product';

const CustomProductItemEdit = ({ item, id }) => {
  const { name_viet: nameVn, name_eng: nameEn, image } = item;
  const language = useSelector((state) => state.switchLanguage);
  const loggedIn = useSelector((state) => state.loggedIn);
  const [isEdit, setIsEdit] = useState(false);
  const [newNameVn, setNameVn] = useState(nameVn);
  const [newNameEn, setNameEn] = useState(nameEn);
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const submitForm = (e) => {
    dispatch(
      updateProductType({
        nameVn: newNameVn,
        nameEn: newNameEn,
        productType: id,
      })
    );
    if (file) dispatch(updateImageProduct({ productType: id, file }));
    setIsEdit(false);
  };

  return isEdit && loggedIn ? (
    <div
      className='col-xl-3 col-lg-3 col-md-4 col-sm-6 '
      style={{ textAlign: 'center', marginBottom: 16 }}
      onClick={() => history.push(`/product/${id}`)}
    >
      <Form
        onSubmit={submitForm}
        style={{ margin: '1em 40px' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Form.Group controlId='productNameVn'>
          <Form.Control
            type='text'
            placeholder={LANGUAGE_SWITCHER[language].productNameVn}
            value={newNameVn}
            onChange={(e) => setNameVn(e.target.value)}
            style={{ border: '1px solid #ced4da' }}
            required
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
          <Form.Control.Feedback
            type='invalid'
            style={{ margin: '-20px 0 10px 5px' }}
          >
            {LANGUAGE_SWITCHER[language].productNameError}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='productNameEn'>
          <Form.Control
            type='text'
            placeholder={LANGUAGE_SWITCHER[language].productNameEn}
            value={newNameEn}
            onChange={(e) => setNameEn(e.target.value)}
            style={{ border: '1px solid #ced4da' }}
            required
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
          <Form.Control.Feedback
            type='invalid'
            style={{ margin: '-20px 0 10px 5px' }}
          >
            {LANGUAGE_SWITCHER[language].productNameError}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.File id='file' custom>
            <Form.File.Input
              accept='image/jpeg,image/jpg,image/png'
              onChange={(e) => setFile(e.target.files[0])}
            />
            <Form.File.Label
              data-browse={LANGUAGE_SWITCHER[language].chooseFile}
            >
              {file?.name}
            </Form.File.Label>
          </Form.File>
        </Form.Group>
        <div className='modal-button'>
          <Button
            variant='secondary'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsEdit(false);
            }}
          >
            {LANGUAGE_SWITCHER[language].back}
          </Button>
          <Button
            variant='primary'
            type='submit'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {LANGUAGE_SWITCHER[language].confirm}
          </Button>
        </div>
      </Form>
    </div>
  ) : loggedIn ? (
    <div
      className='col-xl-3 col-lg-3 col-md-4 col-sm-6 '
      style={{ textAlign: 'center', marginBottom: 16 }}
      onClick={() => history.push(`/product/${id}`)}
    >
      <div style={{ position: 'relative' }}>
        <DropdownButton
          className='dropdown-edit-button'
          id='dropdown-basic-button'
          title={<img src='../../icon/edit.svg' alt='edit-icon' />}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Dropdown.Item onClick={() => setIsEdit(true)}>
            {LANGUAGE_SWITCHER[language].edit}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => dispatch(deleteProductType(id))}>
            {LANGUAGE_SWITCHER[language].delete}
          </Dropdown.Item>
        </DropdownButton>
        <div className='offer_box product_box'>
          <figure>
            <img src={`data:image/png;base64,${image}`} alt='img' />
          </figure>
          <h3>{language === 'EN' ? nameEn : nameVn}</h3>
        </div>
      </div>
    </div>
  ) : (
    ((language === 'EN' && nameEn) || (language === 'VN' && nameVn)) && (
      <div
        className='col-xl-3 col-lg-3 col-md-4 col-sm-6 '
        style={{ textAlign: 'center', marginBottom: 16 }}
        onClick={() => history.push(`/product/${id}`)}
      >
        <div style={{ position: 'relative' }}>
          <div className='offer_box product_box'>
            <figure>
              <img src={`data:image/png;base64,${image}`} alt='img' />
            </figure>
            <h3>{language === 'EN' ? nameEn : nameVn}</h3>
          </div>
        </div>
      </div>
    )
  );
};

export default CustomProductItemEdit;
