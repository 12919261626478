import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';

const CustomSlider = () => {
  const language = useSelector((state) => state.switchLanguage);
  const name = useSelector((state) =>
    language === 'EN'
      ? state.getInfo?.data?.data.name_eng
      : state.getInfo?.data?.data.name_viet
  );
  const content = useSelector((state) =>
    language === 'EN'
      ? state.getInfo?.data?.data.intro_eng
      : state.getInfo?.data?.data.intro_viet
  );
  const history = useHistory();
  return (
    <section className='slider_section'>
      <div
        id='myCarousel'
        className='carousel slide banner-main'
        data-ride='carousel'
      >
        <div className='carousel-inner'>
          <div className='carousel-item active'>
            <img
              className='first-slide'
              src='../../images/banner.jpg'
              alt='First slide'
              style={{ filter: 'brightness(0.3)' }}
            />
            <div className='container'>
              <div className='carousel-caption relative'>
                {language === 'VN' ? (
                  <>
                    <h1>{name?.split(' ').slice(0, -1).join(' ')}</h1>
                    <span>{name?.split(' ').slice(-1)}</span>
                  </>
                ) : (
                  <>
                    <span>{name?.split(' ').slice(0, 1).join(' ')}</span>
                    <h1>{name?.split(' ').slice(1, -1).join(' ')}</h1>
                  </>
                )}

                <p>{content}</p>
                <div
                  className='buynow'
                  onClick={() => {
                    history.push('/about');
                  }}
                >
                  {LANGUAGE_SWITCHER[language].about}
                </div>
                <div
                  className='buynow ggg'
                  onClick={() => {
                    history.push('/product');
                  }}
                >
                  {LANGUAGE_SWITCHER[language].products}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomSlider;
