import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import AddItemModal from '../../components/MainPage/AddItemModal';
import ProductItem from '../../components/ProductPage/ProductItem';

const ProductType = ({ productType }) => {
  const language = useSelector((state) => state.switchLanguage);
  const loggedIn = useSelector((state) => state.loggedIn);
  const types = useSelector((state) => state.getAllProductTypes.data?.data);
  const { status, data } = useSelector((state) => state.getAllProducts);
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (status === 'success') {
      setProducts(data.data[productType]);
    }
  }, [status]);

  return (
    <div id={productType}>
      <ul className='product-list'>
        <h1>
          {types &&
            Object.keys(types).length > 0 &&
            (language === 'EN'
              ? types[productType]?.name_eng
              : types[productType]?.name_viet)}
        </h1>
        {loggedIn && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => setShow(true)}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            + {LANGUAGE_SWITCHER[language].addProduct}
          </button>
        )}
        {products?.map((item) => (
          <ProductItem item={item} key={item.id} />
        ))}
      </ul>
      <AddItemModal
        show={show}
        setShow={(e) => setShow(e)}
        isProduct
        isType={false}
        type={productType}
      />
    </div>
  );
};

export default ProductType;
