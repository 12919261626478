import { Button, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import { postInfo } from '../../redux/reducer/info';
import { toast } from 'react-toastify';
import { useDidUpdateEffect } from '../../redux/util/util';

const CustomAbout = () => {
  const language = useSelector((state) => state.switchLanguage);
  const info = useSelector((state) => state.getInfo?.data?.data);
  const loggedIn = useSelector((state) => state.loggedIn);
  const { status: updateStatus, error: updateError } = useSelector(
    (state) => state.postInfo
  );
  const { status: getStatus, error: getError } = useSelector(
    (state) => state.getInfo
  );
  const [aboutEn, setAboutEn] = useState();
  const [aboutVn, setAboutVn] = useState();
  const [addressEn, setAddressEn] = useState();
  const [addressVn, setAddressVn] = useState();
  const [introEn, setIntroEn] = useState();
  const [introVn, setIntroVn] = useState();
  const [nameEn, setNameEn] = useState();
  const [nameVn, setNameVn] = useState();
  const [contactEn, setContactEn] = useState();
  const [contactVn, setContactVn] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [fax, setFax] = useState();
  const dispatch = useDispatch();
  const submitUpdate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      postInfo({
        addressEn,
        addressVn,
        introEn,
        introVn,
        nameEn,
        nameVn,
        phone,
        contactEn,
        contactVn,
        aboutEn,
        aboutVn,
        email,
        fax,
      })
    );
  };

  useEffect(() => {
    if (getStatus === 'success') {
      setAboutEn(info.about_us_eng);
      setAboutVn(info.about_us_viet);
      setIntroEn(info.intro_eng);
      setIntroVn(info.intro_viet);
      setAddressEn(info.address_eng);
      setAddressVn(info.address_viet);
      setContactEn(info.contact_intro_eng);
      setContactVn(info.contact_intro_viet);
      setEmail(info.email);
      setFax(info.fax);
      setPhone(info.phone);
    }
  }, [getStatus]);

  useDidUpdateEffect(() => {
    if (updateStatus === 'success') {
      toast.success('Successfully updated');
    } else if (updateStatus === 'fail') toast.error(updateError);
  }, [updateStatus]);

  return (
    <div id='about' className='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-7 col-lg-7 col-md-12 co-sm-l2'>
            <div className='about_box'>
              <h2>
                {LANGUAGE_SWITCHER[language].about}
                <br />
                <strong className='black'>
                  {language === 'EN' ? info?.name_eng : info?.name_viet}
                </strong>
              </h2>
              {loggedIn ? (
                <Form onSubmit={submitUpdate}>
                  <Form.Group>
                    <Form.Label>
                      {LANGUAGE_SWITCHER[language].nameEn}
                    </Form.Label>
                    <FormControl
                      as='textarea'
                      aria-label='With textarea'
                      value={nameEn}
                      defaultValue={info?.name_eng}
                      rows='1'
                      onChange={(e) => setNameEn(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      {LANGUAGE_SWITCHER[language].nameVn}
                    </Form.Label>
                    <FormControl
                      as='textarea'
                      aria-label='With textarea'
                      value={nameVn}
                      defaultValue={info?.name_viet}
                      rows='1'
                      onChange={(e) => setNameVn(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      {LANGUAGE_SWITCHER[language].introEn}
                    </Form.Label>
                    <FormControl
                      as='textarea'
                      aria-label='With textarea'
                      value={introEn}
                      defaultValue={info?.intro_eng}
                      rows='2'
                      onChange={(e) => setIntroEn(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {LANGUAGE_SWITCHER[language].introVn}
                    </Form.Label>
                    <FormControl
                      as='textarea'
                      aria-label='With textarea'
                      value={introVn}
                      defaultValue={info?.intro_viet}
                      rows='2'
                      onChange={(e) => setIntroVn(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {LANGUAGE_SWITCHER[language].aboutEn}
                    </Form.Label>
                    <FormControl
                      as='textarea'
                      aria-label='With textarea'
                      value={aboutEn}
                      defaultValue={info?.about_us_eng}
                      rows='5'
                      onChange={(e) => setAboutEn(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {LANGUAGE_SWITCHER[language].aboutVn}
                    </Form.Label>
                    <FormControl
                      as='textarea'
                      aria-label='With textarea'
                      value={aboutVn}
                      defaultValue={info?.about_us_viet}
                      rows='5'
                      onChange={(e) => setAboutVn(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {LANGUAGE_SWITCHER[language].contactEn}
                    </Form.Label>
                    <FormControl
                      as='textarea'
                      aria-label='With textarea'
                      value={contactEn}
                      defaultValue={info?.contact_eng}
                      rows='5'
                      onChange={(e) => setContactEn(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {LANGUAGE_SWITCHER[language].contactVn}
                    </Form.Label>
                    <FormControl
                      as='textarea'
                      aria-label='With textarea'
                      value={contactVn}
                      defaultValue={info?.contact_viet}
                      rows='5'
                      onChange={(e) => setContactVn(e.target.value)}
                    />
                  </Form.Group>
                  <InputGroup size='sm' className='mb-3'>
                    <InputGroup.Prepend>
                      <InputGroup.Text id='inputGroup-sizing-sm'>
                        {LANGUAGE_SWITCHER[language].addressEn}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label='Address'
                      aria-describedby='inputGroup-sizing-sm'
                      value={addressEn}
                      defaultValue={info?.address_eng}
                      onChange={(e) => setAddressEn(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup size='sm' className='mb-3'>
                    <InputGroup.Prepend>
                      <InputGroup.Text id='inputGroup-sizing-sm'>
                        {LANGUAGE_SWITCHER[language].addressVn}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label='Address'
                      aria-describedby='inputGroup-sizing-sm'
                      value={addressVn}
                      defaultValue={info?.address_viet}
                      onChange={(e) => setAddressVn(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup size='sm' className='mb-3'>
                    <InputGroup.Prepend>
                      <InputGroup.Text id='inputGroup-sizing-sm'>
                        Email
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label='Email'
                      aria-describedby='inputGroup-sizing-sm'
                      value={email}
                      defaultValue={info?.email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup size='sm' className='mb-3'>
                    <InputGroup.Prepend>
                      <InputGroup.Text id='inputGroup-sizing-sm'>
                        Fax
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label='Fax'
                      aria-describedby='inputGroup-sizing-sm'
                      value={fax}
                      defaultValue={info?.fax}
                      onChange={(e) => setFax(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup size='sm' className='mb-3'>
                    <InputGroup.Prepend>
                      <InputGroup.Text id='inputGroup-sizing-sm'>
                        Phone
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label='Phone'
                      aria-describedby='inputGroup-sizing-sm'
                      value={phone}
                      defaultValue={info?.phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </InputGroup>
                  <Button variant='primary' type='submit'>
                    {LANGUAGE_SWITCHER[language].confirm}
                  </Button>
                </Form>
              ) : (
                <p>
                  {language === 'EN' ? info?.about_us_eng : info?.about_us_viet}
                </p>
              )}
            </div>
          </div>
          <div className='col-xl-5 col-lg-5 col-md-12 co-sm-l2 dp-ct'>
            <div className='about_img'>
              <figure>
                <img src='../../images/about.png' alt='img' />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAbout;
