import axios from 'axios';
import { createAction } from 'redux-actions';
import { DOMAIN } from '../../constants';
import auth from './auth';

import { useEffect, useRef } from 'react';
export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
}

export const asyncApi = async (dispatch, callbackFunc) => {
  try {
    const response = await callbackFunc;
    return { status: response.status, message: response.data.message };
  } catch (error) {
    console.log(error);
    const err = error.response?.data;
    if (
      err &&
      (err.message === 'Phiên đăng nhập của token hết hạn.' ||
        err.message === 'Token đã hết hiệu lực.')
    )
      dispatch({ type: 'LOGOUT_SUCCESS' });
    return { err };
  }
};

export const asyncActions = (type, callbackFunc) => {
  const TYPE_START = `${type}_START`;
  const TYPE_SUCCESS = `${type}_SUCCESS`;
  const TYPE_FAILURE = `${type}_FAILURE`;

  const actions = {
    [TYPE_START]: createAction(
      TYPE_START,
      (input) => false,
      (input) => false
    ),
    [TYPE_SUCCESS]: createAction(
      TYPE_SUCCESS,
      (input, response) => ({ response: response.data }),
      (input, response) => false
    ),
    [TYPE_FAILURE]: createAction(
      TYPE_FAILURE,
      (input, error) => ({ error: error?.message }),
      (input, error) => false
    ),
  };

  const thunkAction = (input) => async (dispatch, getState) => {
    dispatch(actions[TYPE_START](input));

    return callbackFunc(input)
      .then((response) => {
        dispatch(actions[TYPE_SUCCESS](input, response));
        return response;
      })
      .catch((error) => {
        const err = error.response?.data;
        if (
          err &&
          (err.message === 'Phiên đăng nhập của token hết hạn.' ||
            err.message === 'Token đã hết hiệu lực.')
        )
          return dispatch({ type: 'LOGOUT_SUCCESS' });
        dispatch(actions[TYPE_FAILURE](input, err));
        return { err };
      });
  };
  thunkAction.START = actions[TYPE_START].toString();
  thunkAction.SUCCESS = actions[TYPE_SUCCESS].toString();
  thunkAction.FAILURE = actions[TYPE_FAILURE].toString();

  return thunkAction;
};

export const addActions = (type, callbackFunc) => {
  const TYPE_START = `${type}_START`;
  const TYPE_SUCCESS = `${type}_SUCCESS`;
  const TYPE_FAILURE = `${type}_FAILURE`;

  const actions = {
    [TYPE_START]: createAction(
      TYPE_START,
      (input) => false,
      (input) => false
    ),
    [TYPE_SUCCESS]: createAction(
      TYPE_SUCCESS,
      (input, response) => ({ response: response.data }),
      (input, response) => false
    ),
    [TYPE_FAILURE]: createAction(
      TYPE_FAILURE,
      (input, error) => ({ error: error?.message }),
      (input, error) => false
    ),
  };

  const thunkAction = (input) => async (dispatch, getState) => {
    dispatch(actions[TYPE_START](input));

    return callbackFunc(input)
      .then((response) => {
        console.log(response);
        if (input.file) {
          const form = new FormData();
          form.append('file', input.file);
          axios
            .put(
              `${DOMAIN}/${
                type === 'CREATE_PRODUCT_TYPE' ? 'product' : 'service'
              }/type/image?id=${response.data.data}`,
              form,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${auth.getToken()}`,
                },
              }
            )
            .then(() => dispatch(actions[TYPE_SUCCESS](input, response)));
        } else dispatch(actions[TYPE_SUCCESS](input, response));

        return response;
      })
      .catch((error) => {
        const err = error.response?.data;
        if (
          err &&
          (err.message === 'Phiên đăng nhập của token hết hạn.' ||
            err.message === 'Token đã hết hiệu lực.')
        )
          return dispatch({ type: 'LOGOUT_SUCCESS' });
        dispatch(actions[TYPE_FAILURE](input, err));
        return { err };
      });
  };
  thunkAction.START = actions[TYPE_START].toString();
  thunkAction.SUCCESS = actions[TYPE_SUCCESS].toString();
  thunkAction.FAILURE = actions[TYPE_FAILURE].toString();

  return thunkAction;
};

export const switchLanguageActions = (language) => {
  const TYPE_SUCCESS = `SWITCH_LANGUAGE`;

  const actions = {
    [TYPE_SUCCESS]: createAction(
      TYPE_SUCCESS,
      (input, response) => ({ response: response.language }),
      (input, response) => false
    ),
  };

  const thunkAction = (input) => async (dispatch, getState) => {
    let response;
    if (input === 'EN') {
      response = { language: 'VN' };
    } else response = { language: 'EN' };
    dispatch(actions[TYPE_SUCCESS](input, response));
    return response;
  };
  thunkAction.SUCCESS = actions[TYPE_SUCCESS].toString();

  return thunkAction;
};

export const loggedInActions = (status) => {
  const TYPE_SUCCESS = `CHANGE_LOGGED_IN_SUCCESS`;

  const actions = {
    [TYPE_SUCCESS]: createAction(
      TYPE_SUCCESS,
      (input, response) => ({ response: response.status }),
      (input, response) => false
    ),
  };

  const thunkAction = (input) => async (dispatch, getState) => {
    let status;
    if (input === true) {
      status = { status: true };
    } else status = { status: false };
    dispatch(actions[TYPE_SUCCESS](input, status));
    return status;
  };
  thunkAction.SUCCESS = actions[TYPE_SUCCESS].toString();

  return thunkAction;
};
