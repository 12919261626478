import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';

const CustomFooter = () => {
  const history = useHistory();
  const language = useSelector((state) => state.switchLanguage);
  const info = useSelector((state) => state.getInfo?.data?.data);
  const scroll = (id) => {
    history.push(`/${id}`);
    setTimeout(
      () => document.getElementById(id).scrollIntoView({ behavior: 'smooth' }),
      500
    );
  };

  const goToTop = () => {
    history.push('/');
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  return (
    <footer id='contact'>
      <div className='footer top_layer '>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
              <div className='address'>
                {/* <div
                  onClick={() => history.push("/")}
                  style={{ cursor: "pointer" }}
                  className="logo"
                >
                  <img src="../../images/logo-transparent-white.png" alt="logo" />
                </div> */}
                <div className='name'>
                  <h3>VIMEPHARCO</h3>
                </div>
                <p>
                  {language === 'EN'
                    ? info?.contact_intro_eng
                    : info?.contact_intro_viet}
                </p>
              </div>
            </div>

            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6'>
              <div className='address'>
                <h3>{LANGUAGE_SWITCHER[language].quicklink}</h3>
                <ul className='loca quicklink'>
                  <li>
                    <div onClick={goToTop}>
                      {LANGUAGE_SWITCHER[language].home}
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        scroll('about');
                      }}
                    >
                      {LANGUAGE_SWITCHER[language].about}
                    </div>
                  </li>
                  <li>
                    <div onClick={() => history.push('/product')}>
                      {LANGUAGE_SWITCHER[language].products}
                    </div>
                  </li>
                  <li>
                    <div onClick={() => scroll('service')}>
                      {LANGUAGE_SWITCHER[language].services}
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6'>
              <div className='address'>
                <h3>{LANGUAGE_SWITCHER[language].contact}</h3>

                <ul className='loca'>
                  <li>
                    <div className='dp-ct' style={{ paddingBottom: 0 }}>
                      <img
                        src='../../icon/loc.svg'
                        alt='#'
                        style={{ marginRight: 5 }}
                      />
                      {language === 'EN'
                        ? info?.address_eng
                        : info?.address_viet}
                    </div>
                  </li>
                  <li>
                    <div className='dp-ct' style={{ paddingBottom: 0 }}>
                      <img
                        src='../../icon/email.svg'
                        alt='#'
                        style={{ marginRight: 5 }}
                      />
                      {language === 'EN' ? info?.email : info?.email}
                    </div>
                  </li>
                  <li>
                    <div className='dp-ct' style={{ paddingBottom: 0 }}>
                      <img
                        src='../../icon/call.svg'
                        alt='#'
                        style={{ marginRight: 5 }}
                      />
                      {language === 'EN' ? info?.phone : info?.phone}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CustomFooter;
