import { combineReducers } from "redux";
import {
  changePasswordHandleActions as changePassword,
  loginHandleActions as login,
  logoutHandleActions as logout,
  registerHandleActions as register,
  getRoleHandleActions as getRole,
  getAllUsersHandleActions as getAllUsers,
  getUserHandleActions as getUser,
  updateUserHandleActions as updateUser,
  deleteUserHandleActions as deleteUser,
} from "./auth";
import {
  getInfoHandleActions as getInfo,
  postInfoHandleActions as postInfo,
} from "./info";
import {
  getAllProductsHandleActions as getAllProducts,
  getAllProductsByTypeHandleActions as getAllProductsByType,
  getAllProductTypesHandleActions as getAllProductTypes,
  getProductTypeHandleActions as getProductType,
  createProductTypeHandleActions as createProductType,
  updateProductTypeHandleActions as updateProductType,
  deleteProductTypeHandleActions as deleteProductType,
  createProductHandleActions as createProduct,
  deleteProductHandleActions as deleteProduct,
  updateProductHandleActions as updateProduct,
  updateImageProductHandleActions as updateImageProduct,
} from "./product";
import {
  getAllServiceTypesHandleActions as getAllServiceTypes,
  getServiceTypeHandleActions as getServiceType,
  createServiceTypeHandleActions as createServiceType,
  updateServiceTypeHandleActions as updateServiceType,
  deleteServiceTypeHandleActions as deleteServiceType,
  updateImageServiceHandleActions as updateImageService,
} from "./service";
import {
  switchLanguageHandleActions as switchLanguage,
  loggedInHandleActions as loggedIn,
} from "./setting";

const combinedReducer = combineReducers({
  changePassword,
  login,
  logout,
  register,
  getRole,
  getAllUsers,
  getUser,
  updateUser,
  deleteUser,
  getInfo,
  postInfo,
  getAllProducts,
  getAllProductsByType,
  getAllProductTypes,
  getProductType,
  createProductType,
  updateProductType,
  deleteProductType,
  createProduct,
  deleteProduct,
  updateProduct,
  updateImageProduct,
  getAllServiceTypes,
  getServiceType,
  createServiceType,
  updateServiceType,
  deleteServiceType,
  updateImageService,
  switchLanguage,
  loggedIn,
});

export default combinedReducer;
