import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LANGUAGE_SWITCHER from "../../languageSwitcher/languageDict";
import { changePassword, login, register } from "../../redux/reducer/auth";
import { Form, Modal, Button } from "react-bootstrap";
import { useDebounce } from "use-debounce/lib";

const ChangePasswordModal = ({ show, setShow, isLogIn }) => {
  const language = useSelector((state) => state.switchLanguage);
  const { status } = useSelector((state) => state.changePassword);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const dispatch = useDispatch();

  const submitModal = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (newPassword !== "" && newPasswordConfirm === newPassword) {
      dispatch(changePassword({ oldPassword, newPassword }));
    }
  };

  const close = () => {
    setNewPassword("");
    setNewPasswordConfirm("");
    setShow(false);
  };

  useEffect(() => {
    if (status === "success") {
      setShow(false);
    }
  }, [status]);

  return (
    <Modal show={show} onHide={() => close()} centered>
      <Modal.Header closeButton>
        <Modal.Title>{LANGUAGE_SWITCHER[language].changePassword}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate onSubmit={submitModal}>
          <Form.Group controlId="oldPassword">
            <Form.Control
              type="password"
              placeholder={LANGUAGE_SWITCHER[language].oldPassword}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              style={{ border: "1px solid #ced4da" }}
              required
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ margin: "-20px 0 10px 5px" }}
            >
              {LANGUAGE_SWITCHER[language].passwordError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="newPassword">
            <Form.Control
              type="password"
              placeholder={LANGUAGE_SWITCHER[language].newPassword}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{ border: "1px solid #ced4da" }}
              required
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ margin: "-20px 0 10px 5px" }}
            >
              {LANGUAGE_SWITCHER[language].passwordError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="newPasswordConfirm">
            <Form.Control
              type="password"
              placeholder={LANGUAGE_SWITCHER[language].newPasswordConfirm}
              value={newPasswordConfirm}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
              style={{ border: "1px solid #ced4da" }}
              isInvalid={newPasswordConfirm !== newPassword}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ margin: "-20px 0 10px 5px" }}
            >
              {LANGUAGE_SWITCHER[language].passwordConfirmError}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="modal-button">
            <Button variant="secondary" onClick={() => close()}>
              {LANGUAGE_SWITCHER[language].back}
            </Button>
            <Button variant="primary" type="submit">
              {LANGUAGE_SWITCHER[language].confirm}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
