import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccountPage from "./views/AccountPage";
import MainPage from "./views/MainPage";
import { ToastContainer } from "react-toastify";
import ProductPage from "./views/ProductPage";
import CustomHeader from "./components/common/CustomHeader";
import CustomFooter from "./components/common/CustomFooter";

export default function GlobalRouter() {
  const isLoggedIn = useSelector((state) => state.loggedIn);
  return (
    <Router>
      <CustomHeader />
      <div>
        <Switch>
          {isLoggedIn && (
            <Route path="/account">
              <AccountPage />
            </Route>
          )}
          <Route path="/product/:productType">
            <ProductPage />
          </Route>
          <Route path="/product">
            <ProductPage />
          </Route>
          <Route path="/:section" exact>
            <MainPage />
          </Route>
          <Route path="/" exact>
            <MainPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
      <CustomFooter />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}
