import { handleActions } from 'redux-actions';
import axios from 'axios';
import { addActions, asyncActions } from '../util/util';
import { DOMAIN } from '../../constants';
import Auth from '../util/auth';

const initialState = {
  getAllServiceTypes: [],
  getAllServicesByType: [],
  getServiceType: {},
  createServiceType: {},
  updateServiceType: {},
  deleteServiceType: {},
  updateImageService: {},
};

export const getAllServiceTypes = asyncActions('GET_ALL_SERVICE_TYPES', () => {
  return axios.get(`${DOMAIN}/service/type`);
});

export const getAllServiceTypesHandleActions = handleActions(
  {
    [getAllServiceTypes.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [getAllServiceTypes.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [getAllServiceTypes.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.getAllServiceTypes
);

export const createServiceType = addActions(
  'CREATE_SERVICE_TYPE',
  ({ nameEn, nameVn }) => {
    return axios.post(
      `${DOMAIN}/service/type`,
      {
        name_eng: nameEn,
        name_viet: nameVn,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    );
  }
);

export const createServiceTypeHandleActions = handleActions(
  {
    [createServiceType.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [createServiceType.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [createServiceType.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.createServiceType
);

export const getServiceType = asyncActions(
  'GET_SERVICE_TYPE',
  (serviceType) => {
    return axios.get(`${DOMAIN}/service/type/${serviceType}`);
  }
);

export const getServiceTypeHandleActions = handleActions(
  {
    [getServiceType.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [getServiceType.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [getServiceType.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.getServiceType
);

export const updateServiceType = asyncActions(
  'UPDATE_SERVICE_TYPE',
  ({ serviceType, nameEn, nameVn }) => {
    return axios.put(
      `${DOMAIN}/service/type/${serviceType}`,
      { name_eng: nameEn, name_viet: nameVn },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    );
  }
);

export const updateServiceTypeHandleActions = handleActions(
  {
    [updateServiceType.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [updateServiceType.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [updateServiceType.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.updateServiceType
);

export const deleteServiceType = asyncActions(
  'DELETE_SERVICE_TYPE',
  (serviceType) => {
    return axios.delete(`${DOMAIN}/service/type/${serviceType}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    });
  }
);

export const deleteServiceTypeHandleActions = handleActions(
  {
    [deleteServiceType.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [deleteServiceType.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [deleteServiceType.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.deleteServiceType
);

export const updateImageService = asyncActions(
  'UPDATE_IMAGE_SERVICE',
  ({ serviceType, file }) => {
    const form = new FormData();
    form.append('file', file);
    return axios.put(`${DOMAIN}/service/type/image?id=${serviceType}`, form, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    });
  }
);

export const updateImageServiceHandleActions = handleActions(
  {
    [updateImageService.START]: (state, { payload, meta }) => {
      return {
        status: 'started',
      };
    },
    [updateImageService.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: 'success',
        data: payload.response,
      };
    },
    [updateImageService.FAILURE]: (state, { payload, meta }) => {
      return {
        status: 'fail',
        error: payload.error,
      };
    },
  },
  initialState.updateImageService
);
