import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import { deleteProduct, updateProduct } from '../../redux/reducer/product';
import { Form, Button } from 'react-bootstrap';

const ProductItem = ({ item }) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.switchLanguage);
  const loggedIn = useSelector((state) => state.loggedIn);
  const [nameEn, setNameEn] = useState(item.name_eng);
  const [nameVn, setNameVn] = useState(item.name_viet);
  const submitForm = () => {
    dispatch(
      updateProduct({
        nameEn,
        nameVn,
        id: item.id,
      })
    );
  };

  return loggedIn ? (
    <Form onSubmit={submitForm} style={{ marginBottom: 36 }}>
      <Form.Group controlId='productNameEn'>
        <Form.Control
          type='text'
          placeholder={LANGUAGE_SWITCHER[language].productNameEn}
          value={nameEn}
          onChange={(e) => setNameEn(e.target.value)}
          style={{ border: '1px solid #ced4da' }}
        />
      </Form.Group>
      <Form.Group controlId='productNameVn'>
        <Form.Control
          type='text'
          placeholder={LANGUAGE_SWITCHER[language].productNameVn}
          value={nameVn}
          onChange={(e) => setNameVn(e.target.value)}
          style={{ border: '1px solid #ced4da' }}
        />
      </Form.Group>

      <div style={{ display: 'flex' }}>
        <Button variant='primary' type='submit'>
          {LANGUAGE_SWITCHER[language].update}
        </Button>
        <Button
          className='btn btn-primary'
          onClick={() => dispatch(deleteProduct(item.id))}
          style={{ marginLeft: 10 }}
        >
          {LANGUAGE_SWITCHER[language].delete}
        </Button>
      </div>
    </Form>
  ) : language === 'EN' ? (
    !!item.name_eng && <li className='product-list-item'>{item.name_eng}</li>
  ) : (
    !!item.name_viet && <li className='product-list-item'>{item.name_viet}</li>
  );
};

export default ProductItem;
