import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import { Button, Table } from 'react-bootstrap';
import LogInRegisterModal from '../../components/common/LogInRegisterModal';
import ChangePasswordModal from '../../components/AccountPage/ChangePasswordModal';
import { toast } from 'react-toastify';
import {
  deleteUser,
  getAllUsers,
  getRole,
  getUser,
} from '../../redux/reducer/auth';
import { useDidUpdateEffect } from '../../redux/util/util';

const AccountPage = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.switchLanguage);
  const { status: registerStatus, error: registerError } = useSelector(
    (state) => state.register
  );
  const { status: changePwStatus, error: changePwError } = useSelector(
    (state) => state.changePassword
  );
  const { status: deleteStatus, error: deleteError } = useSelector(
    (state) => state.deleteUser
  );
  const user = useSelector((state) => state.getUser?.data?.data);
  const users = useSelector((state) => state.getAllUsers?.data?.data);
  const [show, setShow] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useDidUpdateEffect(() => {
    if (registerStatus === 'fail') {
      toast.error(registerError);
    } else if (registerStatus === 'success') {
      toast.success('Successfully registered');
      dispatch(getAllUsers());
    }
  }, [registerStatus]);

  useDidUpdateEffect(() => {
    if (changePwStatus === 'fail') {
      toast.error(changePwError);
    } else if (changePwStatus === 'success') {
      toast.success('Successfully changed password');
    }
  }, [changePwStatus]);

  useDidUpdateEffect(() => {
    if (deleteStatus === 'fail') {
      toast.error(deleteError);
    } else if (deleteStatus === 'success') {
      toast.success('Successfully deleted user');
      dispatch(getAllUsers());
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (user) {
      setIsAdmin(user.id === 1 ? true : false);
      dispatch(getAllUsers());
    }
  }, [user]);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <div>
      <div style={{ padding: 16, marginTop: 70 }}>
        <h1
          style={{
            marginTop: 30,
            marginBottom: 24,
            fontWeight: 600,
            fontSize: 30,
          }}
        >
          Admin Page
        </h1>
        <div>
          <Button variant='primary' onClick={() => setChangePassword(true)}>
            {LANGUAGE_SWITCHER[language].changePassword}
          </Button>
        </div>
        {isAdmin && (
          <>
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <Button variant='primary' onClick={() => setShow(true)}>
                {LANGUAGE_SWITCHER[language].addUser}
              </Button>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, i) => (
                  <tr key={user.email}>
                    <td>{i}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                      <Button
                        variant='danger'
                        onClick={() => dispatch(deleteUser(user.id))}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </div>

      <LogInRegisterModal
        show={show}
        setShow={(e) => setShow(e)}
        isLogIn={false}
      />

      <ChangePasswordModal
        show={changePassword}
        setShow={(e) => setChangePassword(e)}
      />
    </div>
  );
};

export default AccountPage;
