import React, { useState } from 'react';
import { DropdownButton, Form, Dropdown, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import {
  deleteServiceType,
  updateServiceType,
  updateImageService,
} from '../../redux/reducer/service';

const CustomOfferItemEdit = ({ item }) => {
  const { name_viet: nameVn, name_eng: nameEn, image, id } = item;
  const language = useSelector((state) => state.switchLanguage);
  const [isEdit, setIsEdit] = useState(false);
  const [newNameVn, setNameVn] = useState(nameVn);
  const [newNameEn, setNameEn] = useState(nameEn);
  const [file, setFile] = useState();
  const dispatch = useDispatch();

  const submitForm = () => {
    if (newNameVn !== '' || newNameEn !== '') {
      setIsEdit(false);
      dispatch(
        updateServiceType({
          serviceType: id,
          nameEn: newNameEn,
          nameVn: newNameVn,
        })
      );
      if (file) dispatch(updateImageService({ serviceType: id, file }));
    } else toast.error('Need input name');
  };

  return (
    <div
      id='offer_box_edit'
      className={'col-xl-4 col-lg-4 col-md-6 col-sm-6'}
      style={{ textAlign: 'center', marginBottom: 16 }}
    >
      {isEdit ? (
        <Form onSubmit={submitForm} style={{ margin: '1em 40px' }}>
          <Form.Group controlId='serviceNameVn'>
            <Form.Control
              type='text'
              placeholder={LANGUAGE_SWITCHER[language].serviceNameVn}
              value={newNameVn}
              onChange={(e) => setNameVn(e.target.value)}
              style={{ border: '1px solid #ced4da' }}
            />
          </Form.Group>
          <Form.Group controlId='serviceNameEn'>
            <Form.Control
              type='text'
              placeholder={LANGUAGE_SWITCHER[language].serviceNameEn}
              value={newNameEn}
              onChange={(e) => setNameEn(e.target.value)}
              style={{ border: '1px solid #ced4da' }}
            />
          </Form.Group>

          <Form.Group>
            <Form.File id='formcheck-api-custom' custom>
              <Form.File.Input
                accept='image/jpeg,image/jpg,image/png'
                onChange={(e) => setFile(e.target.files[0])}
              />
              <Form.File.Label
                data-browse={LANGUAGE_SWITCHER[language].chooseFile}
              >
                {file?.name}
              </Form.File.Label>
            </Form.File>
          </Form.Group>
          <div className='modal-button'>
            <Button variant='secondary' onClick={() => setIsEdit(false)}>
              {LANGUAGE_SWITCHER[language].back}
            </Button>
            <Button variant='primary' type='submit'>
              {LANGUAGE_SWITCHER[language].confirm}
            </Button>
          </div>
        </Form>
      ) : (
        <div style={{ position: 'relative' }}>
          <DropdownButton
            className='dropdown-edit-button'
            id='dropdown-basic-button'
            title={<img src='../../icon/edit.svg' alt='edit-icon' />}
          >
            <Dropdown.Item onClick={() => setIsEdit(true)}>
              {LANGUAGE_SWITCHER[language].edit}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => dispatch(deleteServiceType(id))}>
              {LANGUAGE_SWITCHER[language].delete}
            </Dropdown.Item>
          </DropdownButton>
          <div className='offer_box'>
            <figure>
              <img src={`data:image/png;base64,${image}`} alt='img' />
            </figure>
            <h3>{language === 'EN' ? nameEn : nameVn}</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomOfferItemEdit;
