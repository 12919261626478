import { handleActions } from "redux-actions";
import axios from "axios";
import { asyncActions } from "../util/util";
import { DOMAIN } from "../../constants";
import Auth from "../util/auth";

const initialState = {
  getInfo: {},
  postInfo: {},
};

export const getInfo = asyncActions("GET_INFO", () => {
  return axios.get(`${DOMAIN}/info`);
});

export const getInfoHandleActions = handleActions(
  {
    [getInfo.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [getInfo.SUCCESS]: (state, { payload, meta }) => {
      return {
        status: "success",
        data: payload.response,
      };
    },
    [getInfo.FAILURE]: (state, { payload, meta }) => {
      return {
        status: "fail",
        error: payload.error,
      };
    },
  },
  initialState.getInfo
);

export const postInfo = asyncActions(
  "POST_INFO",
  ({
    addressEn,
    addressVn,
    introEn,
    introVn,
    nameEn,
    nameVn,
    phone,
    contactEn,
    contactVn,
    aboutEn,
    aboutVn,
    email,
    fax,
  }) => {
    return axios.put(
      `${DOMAIN}/info/update`,
      {
        address_eng: addressEn,
        address_viet: addressVn,
        intro_eng: introEn,
        intro_viet: introVn,
        name_eng: nameEn,
        name_viet: nameVn,
        about_us_eng: aboutEn,
        about_us_viet: aboutVn,
        contact_intro_eng: contactEn,
        contact_intro_viet: contactVn,
        phone,
        email,
        fax,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      }
    );
  }
);

export const postInfoHandleActions = handleActions(
  {
    [postInfo.START]: (state, { payload, meta }) => {
      return {
        status: "started",
      };
    },
    [postInfo.SUCCESS]: (state, { payload, meta }) => {
      return { status: "success", data: payload.response };
    },
    [postInfo.FAILURE]: (state, { payload, meta }) => {
      return { status: "fail", error: payload.error };
    },
  },
  initialState.postInfo
);
