import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import { Form, Modal, Button } from 'react-bootstrap';
import { createProduct, createProductType } from '../../redux/reducer/product';
import { toast } from 'react-toastify';
import { createServiceType } from '../../redux/reducer/service';

const AddItemModal = ({ show, setShow, isProduct, isType, type }) => {
  const language = useSelector((state) => state.switchLanguage);
  const { status: logInStatus } = useSelector((state) => state.login);
  const [nameVn, setNameVn] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const submitModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (nameVn || nameEn) {
      const form = {
        nameEn,
        nameVn,
      };
      if (isProduct && isType) {
        dispatch(createProductType({ ...form, file }));
      } else if (isProduct && !isType) {
        dispatch(
          createProduct({
            ...form,
            productType: type,
          })
        );
      } else if (!isProduct) {
        dispatch(createServiceType({ ...form, file }));
      }
      close();
    } else {
      toast.error('Need input name');
    }
  };

  const close = () => {
    setNameVn('');
    setNameEn('');
    setFile();
    setShow(false);
  };

  useEffect(() => {
    if (logInStatus === 'success') {
      setShow(false);
    }
  }, [logInStatus]);

  return (
    <Modal show={show} onHide={() => close()} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {isProduct
            ? LANGUAGE_SWITCHER[language].addProduct
            : LANGUAGE_SWITCHER[language].addService}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate onSubmit={submitModal}>
          <Form.Group controlId='productNameVn'>
            <Form.Control
              type='text'
              placeholder={
                isProduct
                  ? LANGUAGE_SWITCHER[language].productNameVn
                  : LANGUAGE_SWITCHER[language].serviceNameVn
              }
              value={nameVn}
              onChange={(e) => setNameVn(e.target.value)}
              style={{ border: '1px solid #ced4da' }}
            />
          </Form.Group>
          <Form.Group controlId='productNameEn'>
            <Form.Control
              type='text'
              placeholder={
                isProduct
                  ? LANGUAGE_SWITCHER[language].productNameEn
                  : LANGUAGE_SWITCHER[language].serviceNameEn
              }
              value={nameEn}
              onChange={(e) => setNameEn(e.target.value)}
              style={{ border: '1px solid #ced4da' }}
            />
          </Form.Group>
          {isType && (
            <Form.Group>
              <Form.File id='file' custom>
                <Form.File.Input
                  accept='image/jpeg,image/jpg,image/png'
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <Form.File.Label
                  data-browse={LANGUAGE_SWITCHER[language].chooseFile}
                >
                  {file?.name}
                </Form.File.Label>
              </Form.File>
            </Form.Group>
          )}
          <div className='modal-button'>
            <Button variant='secondary' onClick={() => close()}>
              {LANGUAGE_SWITCHER[language].back}
            </Button>
            <Button variant='primary' type='submit' id='confirm-button'>
              {LANGUAGE_SWITCHER[language].confirm}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddItemModal;
