import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import LANGUAGE_SWITCHER from '../../languageSwitcher/languageDict';
import { logout } from '../../redux/reducer/auth';
import { getInfo } from '../../redux/reducer/info';
import { getAllProductTypes } from '../../redux/reducer/product';
import { loggedIn, switchLanguage } from '../../redux/reducer/setting';
import auth from '../../redux/util/auth';
import { useDidUpdateEffect } from '../../redux/util/util';
import LogInRegisterModal from './LogInRegisterModal';

const CustomHeader = () => {
  const language = useSelector((state) => state.switchLanguage);
  const info = useSelector((state) => state.getInfo?.data?.data);
  const productTypes = useSelector(
    (state) => state.getAllProductTypes.data?.data
  );
  const isLoggedIn = useSelector((state) => state.loggedIn);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const {
    status: logInStatus,
    error: logInError,
    data: logInData,
  } = useSelector((state) => state.login);
  const logOutStatus = useSelector((state) => state.logout?.status);

  const scroll = (id) => {
    history.push(`/${id}`);
    setTimeout(
      () => document.getElementById(id).scrollIntoView({ behavior: 'smooth' }),
      500
    );
  };

  const goToTop = () => {
    history.push('/');
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  useDidUpdateEffect(() => {
    if (logInStatus === 'success') {
      auth.login(logInData, true);
      dispatch(loggedIn(true));
      toast.success('Successfully logged in');
    } else if (logInStatus === 'fail') {
      toast.error(logInError);
    }
  }, [logInStatus]);

  useDidUpdateEffect(() => {
    if (logOutStatus && logOutStatus !== 'started') {
      auth.logout();
      dispatch(loggedIn(false));
      toast.success('Successfully logged out');
    }
  }, [logOutStatus]);

  useEffect(() => {
    dispatch(getAllProductTypes());
    auth.isLoggedIn() && dispatch(loggedIn(true));
    dispatch(getInfo());
  }, []);

  return (
    <header>
      <div className='header'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col logo_section'>
              <div className='full'>
                <div className='center-desk'>
                  <div className='logo'>
                    <div
                      onClick={goToTop}
                      className='dp-ct'
                      style={{ cursor: 'pointer' }}
                    >
                      <img src='../../images/logo.png' alt='#' />
                      <div
                        style={{
                          marginLeft: 10,
                          color: '#03254b',
                          fontWeight: 500,
                        }}
                      >
                        {language === 'EN' ? info?.name_eng : info?.name_viet}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12'>
              <div className='location_icon_bottum_tt'>
                <ul>
                  <li style={{ paddingLeft: 0 }}>
                    <img src='../../icon/email1.svg' alt='email' />
                    {info?.email}
                  </li>

                  <li>
                    <img src='../../icon/call1.svg' alt='phone' />
                    {info?.phone}
                  </li>
                  <li>
                    <img src='../../icon/fax.svg' alt='location' />
                    {info?.fax}
                  </li>
                  <li style={{ paddingRight: 5 }}>
                    <img
                      onClick={() => dispatch(switchLanguage('EN'))}
                      src={`../../icon/vn.png`}
                      alt='flag'
                      style={{ cursor: 'pointer', objectFit: 'fill' }}
                    />
                    <img
                      onClick={() => dispatch(switchLanguage('VN'))}
                      src={`../../icon/en.png`}
                      alt='flag'
                      style={{ cursor: 'pointer', objectFit: 'fill' }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>

            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav mr-auto'>
                <li className='nav-item active'>
                  <div onClick={goToTop} className='nav-link'>
                    {LANGUAGE_SWITCHER[language].home}
                  </div>
                </li>
                <li className='nav-item'>
                  <div
                    onClick={() => {
                      scroll('about');
                    }}
                    className='nav-link'
                  >
                    {LANGUAGE_SWITCHER[language].about}
                  </div>
                </li>
                <li className='nav-item dropdown'>
                  <div
                    onClick={() => history.push('/product')}
                    className='nav-link'
                    id='productDropdown'
                  >
                    {LANGUAGE_SWITCHER[language].products}
                  </div>
                  <div
                    className='dropdown-menu'
                    aria-labelledby='productDropdown'
                  >
                    {productTypes &&
                      Object.keys(productTypes)?.map(
                        (id) =>
                          ((language === 'EN' && productTypes[id].name_eng) ||
                            (language === 'VN' &&
                              productTypes[id].name_viet)) && (
                            <div
                              className='dropdown-item'
                              key={id}
                              onClick={() => history.push(`/product/${id}`)}
                            >
                              {language === 'EN'
                                ? productTypes[id].name_eng
                                : productTypes[id].name_viet}
                            </div>
                          )
                      )}
                  </div>
                </li>
                <li className='nav-item'>
                  <div onClick={() => scroll('service')} className='nav-link'>
                    {LANGUAGE_SWITCHER[language].services}
                  </div>
                </li>

                <li className='nav-item'>
                  <div onClick={() => scroll('contact')} className='nav-link'>
                    {LANGUAGE_SWITCHER[language].contact}
                  </div>
                </li>

                {isLoggedIn ? (
                  <>
                    <li className='nav-item '>
                      <div
                        className='nav-link'
                        onClick={() => history.push('/account')}
                      >
                        {LANGUAGE_SWITCHER[language].account}
                      </div>
                    </li>
                    <li className='nav-item '>
                      <div
                        className='nav-link'
                        onClick={() => dispatch(logout())}
                      >
                        {LANGUAGE_SWITCHER[language].logOut}
                      </div>
                    </li>
                  </>
                ) : (
                  <li className='nav-item '>
                    <div className='nav-link' onClick={() => setShow(true)}>
                      {LANGUAGE_SWITCHER[language].logIn}
                    </div>
                  </li>
                )}
                <li className='nav-item nav-item-hidden'>
                  <img
                    onClick={() => dispatch(switchLanguage('EN'))}
                    src={`../../icon/vn.png`}
                    alt='flag'
                    style={{
                      marginRight: 5,
                    }}
                  />
                  <img
                    onClick={() => dispatch(switchLanguage('VN'))}
                    src={`../../icon/en.png`}
                    alt='flag'
                  />
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <LogInRegisterModal show={show} setShow={setShow} isLogIn={!isLoggedIn} />
    </header>
  );
};

export default CustomHeader;
