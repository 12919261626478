import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import LANGUAGE_SWITCHER from "../../languageSwitcher/languageDict";
import AddItemModal from "./AddItemModal";

const CustomAddItem = ({ isProduct }) => {
  const [show, setShow] = useState(false);
  const language = useSelector((state) => state.switchLanguage);
  return (
    <div
      className={
        isProduct
          ? "col-xl-3 col-lg-3 col-md-4 col-sm-6"
          : "col-xl-4 col-lg-4 col-md-6 col-sm-6"
      }
      style={{ textAlign: "center", marginBottom: 16 }}
    >
      <div style={{ position: "relative" }}>
        <div className="product_box">
          <figure onClick={() => setShow(true)}>
            <div className="add-container">
              <div style={{ fontSize: 18 }}>
                {LANGUAGE_SWITCHER[language].add}
              </div>
              <div style={{ fontSize: 30 }}>+</div>
            </div>
          </figure>
        </div>
      </div>
      <AddItemModal
        show={show}
        setShow={(e) => setShow(e)}
        isProduct={isProduct}
        isType
      />
    </div>
  );
};

export default CustomAddItem;
